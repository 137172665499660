import {
    Box,
    Button,
    Center, Checkbox, FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel, Heading, HStack,
    Input, InputGroup,
    InputRightElement, Spacer, Text, useMediaQuery,
    VStack
} from "@chakra-ui/react";
import {FormEvent, useCallback, useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {getSupplierInfo, updatePassword} from "../../hooks/libs/supplier";


export default function InitPasswordPage() {
    const [searchParams] = useSearchParams();

    const token = searchParams.get("token") ?? undefined;

    const [email, setEmail] = useState("");
    const [nickname, setNickname] = useState("");
    const [mobile, setMobile] = useState("");

    const [password, setPassword] = useState("");
    const [show, setShow] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const onSubmitPassword = useCallback(async (e: FormEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (token === undefined) return;
        const result = await updatePassword({
            token,
            password,
        });
        if (result) {
            alert("변경 완료.");
            window.location.replace("https://pixt.is/login");
        } else {
            alert("변경 실패 문의 바랍니다.");
        }
    }, [token, password]);

    const loadInfo = useCallback(async () => {
        if (token === undefined) return;
        const info = await getSupplierInfo(token);
        if (info === null) {
            alert("정보 불러오기 실패");
            return;
        }
        setEmail(info.email);
        setNickname(info.nickname);
        setMobile(info.mobile ?? "<미등록>")
    }, [token]);

    useEffect(() => {
        loadInfo();
    }, [loadInfo]);

    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,\-./:;<=>?@\[＼\]^_`{|}~\\])[A-Za-z\d!"#$%&'()*+,\-./:;<=>?@\[＼\]^_`{|}~\\]{8,32}$/g;
    const isPasswordError = !passwordRegex.test(password);

    const isValid = !isPasswordError && confirm;

    if (token === undefined) {
        return <Center>
            <Heading>
                잘못된 접근 방법입니다.
            </Heading>
        </Center>;
    }

    return <Center h="100%" bgColor="brand.50">
        <VStack
            p="16px"
            boxShadow="xl"
            borderRadius="xl"
            borderColor="brand.100"
            borderWidth="2px"
            bgColor="white"
            marginX="32px"
            maxWidth="480px"
            maxHeight="640px"
            height="100%"
            width="100%"
            alignItems="flex-start"
        >
            <Heading size="lg">비밀번호 설정</Heading>
            <Text>신청하셨던 정보와 동일한지 확인 해주시길 바랍니다.</Text>
            <Box h="16px" />
            <Heading size="md">사용자 정보</Heading>
            <InfoField label="이메일" value={email} />
            <InfoField label="닉네임" value={nickname} />
            <InfoField label="휴대폰번호" value={mobile} />
            <Box h="16px" />
            <VStack as="form" w="100%" h="100%" alignItems="stretch" onSubmit={onSubmitPassword}>
                <FormControl isInvalid={isPasswordError}>
                    <FormLabel>
                        비밀번호
                    </FormLabel>
                    <InputGroup>
                        <Input
                            pr="4.5rem"
                            placeholder="password"
                            type={show ? "text" : "password"}
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <InputRightElement width="4.5rem">
                            <Button size="sm" onClick={() => setShow(prevState => !prevState)}>
                                {show ? "숨기기" : "보기"}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                    {
                        isPasswordError ? <FormErrorMessage>
                            8자 이상, 3조합 (숫자, 영어, 특수문자) 형식을 맞춰주세요!
                        </FormErrorMessage> : <FormHelperText>
                            비밀번호 형식이 맞습니다!
                        </FormHelperText>
                    }
                </FormControl>
                <Spacer />
                <Checkbox
                    isChecked={confirm}
                    onChange={e => setConfirm(e.target.checked)}
                >
                    신청 정보와 동일하며 본인이 맞음
                </Checkbox>
                <Button disabled={!isValid} type="submit">
                    변경
                </Button>
            </VStack>
        </VStack>
    </Center>;
}

type InfoFieldProps = {
    label: string;
    value: string;
}

function InfoField({label, value}: InfoFieldProps) {
    const [isMobile] = useMediaQuery('(max-width: 500px)');
    return isMobile ?
        <VStack w="100%" alignItems="flex-start" spacing="0">
            <Text color="gray.500">{label}</Text>
            <Text>{value}</Text>
        </VStack> :
        <HStack w="100%" flexFlow="wrap">
            <Text color="gray.500">{label}</Text>
            <Spacer />
            <Text>{value}</Text>
        </HStack>;
}
