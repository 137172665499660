import apiClient from "./apiClient";


export type SupplierSimpleInfo = {
    email: string;
    nickname: string;
    mobile?: string;
}

export function getSupplierInfo(token: string) {
    return apiClient.get<SupplierSimpleInfo>(`/tp/supplier-simple-info?token=${token}`)
        .then(value => value.data)
        .catch(reason => null)
}

export type UpdateSupplierPassword = {
    token: string;
    password: string;
}

export function updatePassword(data: UpdateSupplierPassword) {
    return apiClient.patch("/tp/supplier-password", data)
        .then(() => true)
        .catch(() => false)
}