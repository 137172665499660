import {ChakraProvider, extendTheme, withDefaultColorScheme} from '@chakra-ui/react';
import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import InitPasswordPage from "./pages/InitPassword";
import NotFoundPage from "./pages/NotFoundPage";

const theme = extendTheme(
    {
        styles: {
            global: {
                'html, body, #root': {
                    height: "100%",
                    overflowY: "hidden",
                }
            }
        },

        colors: {
            brand: {
                50: '#eae7ff',
                100: '#c1bbfb',
                200: '#978df2',
                300: '#6e60ec',
                400: '#4532e5',
                500: '#2c1acc',
                600: '#21139f',
                700: '#170e73',
                800: '#0c0747',
                900: '#05021d',
            },
        },
    },
    withDefaultColorScheme({ colorScheme: "brand" }),
)

export default function App() {
  return <ChakraProvider theme={theme}>
      <BrowserRouter>
          <Routes>
              <Route path="/init-password" element={<InitPasswordPage />}/>

              <Route path="*" element={<Navigate to="/not-found" />} />
              <Route path="/not-found" element={<NotFoundPage />} />
          </Routes>
      </BrowserRouter>
  </ChakraProvider>;
}

